import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import { routeNames } from "@/constants/nav";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: routeNames.HOME,
    component: Home,
    meta: {
      title: '峨眉山行愿慈善事业基金会'
    }
  },
  {
    path: '/about',
    name: routeNames.ABOUT,
    component: () => import(/* webpackChunkName: "about" */ '@/views/About'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/news',
    name: routeNames.NEWS,
    component: () => import(/* webpackChunkName: "news" */ '@/views/News'),
    meta: {
      title: '行愿资讯'
    }
  },
  {
    path: '/notice',
    name: routeNames.NOTICE,
    component: () => import(/* webpackChunkName: "news" */ '@/views/Notice'),
    meta: {
      title: '信息公示'
    }
  },
  {
    path: '/project',
    name: routeNames.PROJECT,
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project'),
    meta: {
      title: '行愿项目'
    }
  },
  {
    path: '/donate',
    name: routeNames.DONATE,
    component: () => import(/* webpackChunkName: "donate" */ '@/views/Donate'),
    meta: {
      title: '我要捐赠'
    }
  },
  {
    path: '/article',
    name: routeNames.ARTICLE,
    component: () => import(/* webpackChunkName: "article" */ '@/views/Article'),
    meta: {
      title: '行愿资讯'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // if (to.meta.title) document.title = to.meta.title
  next()
})

export default router
