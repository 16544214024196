<template>
  <div id="app">
    <page-header :about-options="aboutOptions"/>
    <div class="page-content safe-area" :class="{'dark-bg': isDarkBg}">
      <router-view/>
    </div>
    <page-footer/>
  </div>
</template>

<style lang="scss">

</style>
<script>
  import PageHeader from "@/components/Common/PageHeader";
  import PageFooter from "@/components/Common/PageFooter";
  import { routeNames } from "@/constants/nav";
  import { emeiApi } from "@/service/api/emei";

  export default {
    components: { PageFooter, PageHeader },
    data() {
      return {
        aboutOptions: []
      }
    },
    computed: {
      isDarkBg() {
        const darkBgList = [routeNames.ABOUT]
        return darkBgList.includes(this.$route.name)
      }
    },
    created() {
      if (this.isMobile()) window.location.href = process.env.VUE_APP_REDIRECT_MOBILE
      emeiApi.getAboutList().then(({ result }) => {
        this.aboutOptions = result.filter(item => item.id !== '6').map(item => ({
          label: item.title,
          value: item.id
        }))
      })
    },
    methods: {
      isMobile() {
        const regex = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        const flag = navigator.userAgent.match(regex)
        return Array.isArray(flag)
      }
    }
  }
</script>

<style lang="scss">
  #app {
    padding-top: 70px;
    .page-content {
      min-height: calc(100vh - 70px - 242px);
    }
    .dark-bg {
      background-color: $color-bg-dark;
    }
  }
</style>
