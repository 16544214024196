<template>
  <div class="page-header dividing-line">
    <div class="safe-area space-between">
      <img class="logo" src="~@/assets/images/nav_logo.png" @click="jumpTo(routeNames.HOME)">
      <div class="nav-wrapper space-between vertical-center">
        <div
          v-for="nav in navOptions"
          :key="nav.label"
          :class="['nav-item', {'is-active': isActiveNav(nav.name, nav.id)}]"
          @click="handleJumpTo(nav, nav.id)"
        >
          <span v-if="nav.name !== routeNames.ABOUT || nav.id">{{ nav.label }}</span>
          <el-dropdown v-else placement="bottom">
            <div :class="['nav-about', {'is-active': $route.name === routeNames.ABOUT && $route.query.id !== '6'}]">
              {{ nav.label }}
            </div>
            <el-dropdown-menu slot="dropdown" class="about-dropdown">
              <el-dropdown-item
                v-for="option in aboutOptions"
                :key="option.value"
                class="flex-center"
                @click.native="handleJumpTo(nav, option.value)"
              >
                {{ option.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { navOptions, routeNames } from "@/constants/nav";

  export default {
    name: "PageHeader",
    props: {
      aboutOptions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        routeNames,
        navOptions,
      }
    },
    methods: {
      isActiveNav(navName, navId) {
        const { name, query: { id } } = this.$route
        if (id) return id === navId
        return name === navName && id === navId
      },
      handleJumpTo(nav, id) {
        if (nav.name === this.$route.name && id === this.$route.query.id) return
        this.jumpTo(nav.name, { id })
      }
    }
  }
</script>

<style scoped lang="scss">
  .page-header {
    width: 100vw;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    .logo {
      width: 309px;
      height: 70px;
      cursor: pointer;
    }
    .nav-wrapper {
      .nav-item {
        height: 100%;
        line-height: 70px;
        font-size: 17px;
        margin-left: 42px;
        cursor: pointer;
        position: relative;
        &:hover {
          color: $color-primary;
        }
      }
      .is-active {
        color: $color-primary !important;
      }
      .nav-about {
        font-size: 17px;
        color: $font-color-1;
        outline: none;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .about-dropdown .el-dropdown-menu__item {
    width: 135px;
    &:focus, &:not(.is-disabled):hover {
      background-color: $color-bg-dark;
    }
  }
</style>
