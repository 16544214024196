import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import '@/styles/element-variables.scss'
Vue.use(ElementUI)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import '@/styles/global.scss'
import '@/mixins'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
