<template>
  <div class="home-page">
    <home-banner v-if="bannerList.length === 1" :banner-list="bannerList"/>
    <home-swiper v-else-if="offsetWidth > 0 && bannerList.length > 1" :offset-width="offsetWidth" :banner-list="bannerList"/>
    <publicity-panel :publicity-list="publicityList"/>
    <div class="space-between" style="margin-top: 40px;">
      <donation-item v-for="option in donationOptions" :key="option.title" :info="option"/>
    </div>
    <home-title title="最新动态"/>
    <latest-news :news-list="latestNewsList"/>
    <div class="fs12 flex-center">
      <span class="more" @click="jumpTo('news')">查看更多</span>
    </div>
    <home-title title="行愿项目"/>
    <project-panel :project-options="projectOptions"/>
    <home-title title="关于我们"/>
    <about-panel/>
  </div>
</template>

<script>
  import { chunk } from 'lodash'
  import PublicityPanel from "@/components/Home/PublicityPanel";
  import DonationItem from "@/components/Home/DonationItem";
  import LatestNews from "@/components/Home/LatestNews";
  import HomeTitle from "@/components/Home/HomeTitle";
  import ProjectPanel from "@/components/Home/ProjectPanel";
  import AboutPanel from "@/components/Home/AboutPanel";
  import HomeSwiper from "@/components/Home/HomeSwiper";
  import HomeBanner from "@/components/Home/HomeBanner";
  import { routeNames } from "@/constants/nav";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: 'Home',
    components: {
      HomeBanner,
      HomeSwiper,
      AboutPanel,
      ProjectPanel,
      HomeTitle,
      LatestNews,
      DonationItem,
      PublicityPanel
    },
    data() {
      return {
        offsetWidth: 0,
        bannerList: [],
        publicityList: [],
        donationOptions: [],
        projectOptions: [],
        newsList: [],
      }
    },
    computed: {
      latestNewsList() {
        const newsList = chunk(this.newsList.slice(0, 6), 3)
        return newsList.map(list => {
          if (list.length % 3 === 2) list.push({})
          return list
        })
      }
    },
    mounted() {
      this.offsetWidth = document.body.offsetWidth
      this.fetchBannerList()
      this.fetchNewsList()
      this.fetchDonationList()
      this.fetchProjectList()
      this.fetchAboutList()
    },
    methods: {
      async fetchBannerList() {
        const { result: { records } } = await emeiApi.getBannerList({ typeCode: 'pc_index_banner' })
        this.bannerList = records
      },
      async fetchNewsList() {
        const { result: { records } } = await emeiApi.getNewsList({
          pageNo: 1,
          pageSize: 6,
          typeCode: ''
        })
        this.newsList = records
      },
      async fetchDonationList() {
        const { result } = await emeiApi.getDonationList()
        this.publicityList = result.map(item => ({ name: item.anonymous ? item.realname.substring(0, 1) + '***' : item.realname, value: item.payMoney }))
      },
      async fetchProjectList() {
        const { result } = await emeiApi.getProjectList()
        this.projectOptions = result.map(item => ({
          id: item.id,
          title: item.title,
          subtitle: item.secondTitle,
          desc: item.summary,
          img: item.bgImg ? item.bgImg : ''
        }))
      },
      async fetchAboutList() {
        emeiApi.getAboutList().then(({ result }) => {
          // const joinItem = result.find(item => item.id === '6')
          const aboutItem = result.find(item => item.id === '1')
          const applyItem = result.find(item => item.id === '4')
          this.donationOptions = [
            {
              title: '我要捐赠',
              btnText: '捐赠方式',
              desc: '峨眉山行愿慈善事业基金会是在四川省民政厅注册的公益基金会。社会各界人士的爱心捐款全部进入本基金会账户，财务透明，定期公布。本基金会诚恳地邀请社会与媒体共同监督',
              img: require('@/assets/images/donation_me.png'),
              route: routeNames.ABOUT,
              id: '3',
            },
            {
              title: '关于我们',
              btnText: '点击查看',
              desc: aboutItem.content,
              img: require('@/assets/images/donation_join.png'),
              route: routeNames.ABOUT,
              id: aboutItem.id
            },
            {
              title: '资助申领',
              btnText: '立即申领',
              desc: applyItem.content,
              img: require('@/assets/images/donation_apply.png'),
              route: routeNames.ABOUT,
              id: applyItem.id
            }
          ]
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .home-page {
    padding-bottom: 100px;
    .more {
      margin-top: 20px;
      padding: 8px 14px;
      background-color: #F0F1F5;
      border-radius: 14px;
      color: #333333;
      cursor: pointer;
    }
  }
</style>
