<template>
  <div class="publicity-panel flex-center">
    <div class="vision-wrapper">
      <img class="heart" src="~@/assets/svgs/heart.svg" alt="">
      <div class="fs22">爱心捐赠公示</div>
      <div class="vision fs12">涓滴之水成海洋，颗颗爱心变希望</div>
    </div>
    <vue-seamless :data="publicityList1" class="publicity-list">
      <div v-for="(item, index) in publicityList1" :key="index" class="space-between vertical-center fs12 publicity-item">
        <i class="iconfont iconicon_home_aixinsmall"/>
        <div class="username">{{ item.name }}</div>
        <div>¥ {{ item.value }}</div>
      </div>
    </vue-seamless>
    <vue-seamless :data="publicityList2" class="publicity-list publicity-list-2">
      <div v-for="(item, index) in publicityList2" :key="index" class="space-between vertical-center fs12 publicity-item">
        <i class="iconfont iconicon_home_aixinsmall"/>
        <div class="username">{{ item.name }}</div>
        <div>¥ {{ item.value }}</div>
      </div>
    </vue-seamless>
    <div v-show="false" class="qr-wrapper">
      <img class="qr" src="~@/assets/images/weixin_qr.png" alt="">
      <div>微信扫码关注我们</div>
    </div>
  </div>
</template>

<script>
  import vueSeamless from 'vue-seamless-scroll'

  export default {
    name: "PublicityPanel",
    components: { vueSeamless },
    props: {
      publicityList: {
        type: Array,
        required: true
      }
    },
    computed: {
      publicityList1() {
        return this.publicityList.filter((item, index) => index < this.publicityList.length / 2)
      },
      publicityList2() {
        return this.publicityList.filter((item, index) => index >= this.publicityList.length / 2)
      },
    }
  }
</script>

<style scoped lang="scss">
  .publicity-panel {
    height: 254px;
    margin-top: 30px;
    background: url("~@/assets/images/publicity_panel_bg.jpg") no-repeat;
    color: white;
    background-size: cover;
    justify-content: space-evenly;
    .vision-wrapper {
      text-align: center;
      .heart {
        width: 120px;
        height: 120px;
      }
      .vision {
        margin-top: 12px;
        color: rgba(white, 0.6);
      }
    }
    .publicity-list {
      width: 428px;
      height: 200px;
      padding: 0 50px 0 20px;
      box-shadow: -1px 0 0 0 #5e4e41 inset;
      overflow: hidden;
      .publicity-item {
        color: rgba(white, 0.8);
        margin-bottom: 14px;
        i {
          color: $color-primary;
        }
        .username {
          flex: 1;
          margin: 0 9px;
        }
      }
    }
    .publicity-list-2 {
      box-shadow: none;
    }
    .qr-wrapper {
      text-align: center;
      .qr {
        width: 170px;
        height: 170px;
        margin-bottom: 16px;
      }
    }
  }
</style>
