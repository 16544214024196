<template>
  <el-carousel
    v-if="bannerList.length > 0"
    class="home-banner"
    height="400px"
    arrow="hover"
    indicator-position="none"
    @change="handleBannerChange"
  >
    <el-carousel-item
      v-for="item in bannerList"
      :key="item.id"
      class="banner-item"
      @click.native="handleBannerJump"
    >
      <img :src="item.img" alt="">
    </el-carousel-item>
    <div class="banner-info fs22 space-between">
      <div class="text-ellipsis">{{ bannerList[currentBanner].title }}</div>
      <div>
        <span>{{ currentBanner + 1 }}</span>
        <span class="fs16">/{{ bannerList.length }}</span>
      </div>
    </div>
  </el-carousel>
</template>

<script>
  import { routeNames } from "@/constants/nav";

  export default {
    name: "HomeBanner",
    props: {
      bannerList: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        routeNames,
        currentBanner: 0
      }
    },
    methods: {
      handleBannerChange(currentIndex) {
        this.currentBanner = currentIndex
      },
      handleBannerJump() {
        const banner = this.bannerList[this.currentBanner]
        if (banner.url) window.location.href = banner.url
      }
    }
  }
</script>

<style scoped lang="scss">
  .home-banner {
    position: relative;
    overflow-y: hidden;
    .banner-item {
      cursor: pointer;
      img {
        width: 100%;
        height: 400px;
      }
    }
    .banner-info {
      width: 1200px;
      height: 80px;
      line-height: 80px;
      padding: 0 30px;
      background: linear-gradient(180deg, rgba(black, 0), rgba(black, 0.4));
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: none;
    }
  }
</style>
