import http from '@/service/request'

export const emeiApi = {
  getBannerList: params => http({ method: 'GET', url: '/common/frontend/advertise/active_list', params }),
  getAboutList: () => http({ method: 'GET', url: '/em/admin/we_chat/getAboutUsList' }),
  getProjectList: () => http({ method: 'GET', url: '/em/admin/we_chat/getSincereItemList' }),
  getNewsList: params => http({ method: 'GET', url: '/em/admin/dict/getArticle', params }),
  getNoticeList: params => http({ method: 'GET', url: '/em/admin/InfoPublicity/list', params }),
  getArticle: params => http({ method: 'GET', url: '/common/frontend/article/queryById', params }),
  getDonationList: () => http({ method: 'GET', url: '/em/frontend/donation/getMonthDonation' }),
}
