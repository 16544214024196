<template>
  <dot-carousel height="340px" :number="newsList.length">
    <el-carousel-item v-for="(newsPage, index) in newsList" :key="index" class="news-page space-between">
      <div v-for="news in newsPage" :key="news.id" class="news-item transition-300">
        <news-item v-if="news.id" :news="news"/>
        <div v-else class="news-placeholder"></div>
      </div>
    </el-carousel-item>
  </dot-carousel>
</template>

<script>
  import { routeNames } from "@/constants/nav";
  import NewsItem from "@/components/Common/NewsItem";
  import DotCarousel from "@/components/Common/DotCarousel";

  export default {
    name: "LatestNews",
    components: { DotCarousel, NewsItem },
    props: {
      newsList: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        routeNames,
      }
    }
  }
</script>

<style scoped lang="scss">
  .news-page {
    .news-placeholder {
      width: 386px;
      height: 100px;
    }
  }
</style>
