<template>
  <div class="donation-item" :style="{background}">
    <div class="mask transition-300">
      <div class="title fs22 transition-600">{{ info.title }}</div>
      <!--<div class="desc fs12 transition-600" v-html="info.desc"></div>-->
      <div class="btn flex-center transition-600" @click="jumpTo(info.route, { id: info.id })">
        {{ info.btnText }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DonationItem",
    props: {
      info: {
        type: Object,
        required: true
      }
    },
    computed: {
      background() {
        return `url(${ this.info.img })`
      }
    }
  }
</script>

<style scoped lang="scss">
  .donation-item {
    width: 386px;
    height: 310px;
    text-align: center;
    background-size: cover !important;
    color: white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .mask {
      width: 100%;
      height: 100%;
      padding: 0 30px;
      top: 0;
      left: 0;
      position: absolute;
    }
    .title {
      margin-top: 144px;
    }
    .desc {
      line-height: 22px;
      margin-top: 56px;
      color: rgba(white, 0.8);
      opacity: 0;
    }
    .btn {
      width: 140px;
      height: 40px;
      border-radius: 20px;
      background-color: rgba(white, 0.3);
      margin: 84px auto 0;
      opacity: 0;
      &:hover {
        background-color: white;
        color: $color-primary;
      }
    }
  }

  .donation-item:hover {
    .mask {
      background-color: rgba(#A65300, 0.7);
    }
    .title {
      margin-top: 52px;
    }
    .desc {
      margin-top: 26px;
      opacity: 1;
    }
    .btn {
      margin-top: 64px;
      opacity: 1;
    }
  }
</style>
