<template>
  <div class="news-item transition-300" @click="openTo(routeNames.ARTICLE, { id: news.id })">
    <div class="cover-wrapper">
      <img class="cover transition-300" :src="news.img || require('@/assets/images/placeholder.png')" alt="">
    </div>
    <div class="title text-ellipsis-2 flex-center transition-300">{{ news.title }}</div>
  </div>
</template>

<script>
  import { routeNames } from "@/constants/nav";

  export default {
    name: "NewsItem",
    props: {
      news: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        routeNames
      }
    }
  }
</script>

<style scoped lang="scss">
  .news-item {
    width: 386px;
    background-color: $color-bg-light;
    cursor: pointer;
    .cover-wrapper {
      width: 100%;
      height: 226px;
      overflow: hidden;
      .cover {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      height: 66px;
      padding: 12px;
      line-height: 24px;
    }
    &:hover {
      box-shadow: 0 4px 25px 0 rgba(#091224, 0.15);
      .cover {
        transform: scale(1.2);
      }
      .title {
        background-color: white;
      }
    }
  }

</style>
