<template>
  <div class="page-footer fs12">
    <div class="safe-area">
      <div class="footer-panel space-between vertical-center">
        <img class="logo" src="~@/assets/images/footer_logo.png" alt="">
        <div class="info-wrapper space-between">
          <div class="title">联系我们</div>
          <div>联系电话：0833-5591966</div>
          <div>电子邮件：xingyuanfoundation@foxmail.com</div>
          <div>联系地址：四川省峨眉山市佛光南路274号大佛禅院</div>
          <div>邮政编码：614200</div>
        </div>
        <div v-show="false" class="qr-wrapper">
          <img class="qr" src="~@/assets/images/weixin_qr.png" alt="">
          <div>扫码关注微信公众号</div>
        </div>
      </div>
      <div class="copyright flex-center">
        <span style="margin-right: 40px;">© 2014 峨眉山行愿慈善事业基金会 版权所有</span>
        <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">备案号：蜀ICP备13025135号</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PageFooter"
  }
</script>

<style scoped lang="scss">
  .page-footer {
    background-color: #2a2118;
    color: #A69E95;
    .footer-panel {
      height: 200px;
      .logo {
        width: 309px;
        height: 70px;
        padding: 23px 20px 23px 0;
        box-shadow: -1px 0px 0px 0px #3c3125 inset;
      }
      .info-wrapper {
        height: 116px;
        padding-left: 40px;
        flex: 1;
        flex-direction: column;
        .title {
          color: #F2EBE5;
          margin-bottom: 8px;
        }
      }
      .qr {
        width: 122px;
        height: 122px;
        margin-bottom: 16px;
      }
    }
    .copyright {
      height: 42px;
      box-shadow: 0 1px 0 0 #3c3125 inset;
      .beian {
        text-decoration: none;
        &:visited {
          color: #A69E95;
        }
      }
    }
  }
</style>
