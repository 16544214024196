<template>
  <div class="about-panel">
    <div class="tab-bar space-between">
      <div
        v-for="(tab, index) in tabList"
        :key="tab.label"
        class="tab-item flex-center fs16"
        :class="{'is-active': index === currentTab}"
        @click="handleSwitchTab(index)"
      >
        {{ tab.label }}
      </div>
    </div>

    <div v-if="tabList.length > 0" class="content">
      <div v-if="currentTab === 0" class="about-desc">
        <img src="~@/assets/images/home_about.png" alt="">
        <div class="desc-content space-between">
          <div class="title fs22">峨眉山行愿慈善事业基金会</div>
          <div class="desc" v-html="tabList[currentTab].content"></div>
          <div class="text-btn more fs12 transition-300" @click="jumpTo(routeNames.ABOUT, { id: tabList[currentTab].value })">
            查看更多
            <i class="iconfont iconicon_home_more fs12"/>
          </div>
        </div>
      </div>

      <div v-else class="about-item">
        <div v-html="tabList[currentTab].content"></div>
        <div class="more text-btn fs12 transition-300" @click="jumpTo(routeNames.ABOUT, { id: tabList[currentTab].value })">
          查看更多
          <i class="iconfont iconicon_home_more fs12"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { routeNames } from "@/constants/nav";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "AboutPanel",
    data() {
      return {
        routeNames,
        tabList: [],
        currentTab: 0
      }
    },
    mounted() {
      emeiApi.getAboutList().then(({ result }) => {
        this.tabList = result.map(item => ({
          label: item.title,
          value: item.id,
          content: item.summary.replace(/\n/g, '<br>')
        })).slice(0, 4)
      })
    },
    methods: {
      handleSwitchTab(index) {
        this.currentTab = index
      }
    }
  }
</script>

<style scoped lang="scss">
  .about-panel {
    .tab-bar {
      margin-bottom: 40px;
      .tab-item {
        width: 300px;
        height: 60px;
        background-color: $color-bg-dark;
        box-shadow: -1px 0 0 0 #dedede inset;
        cursor: pointer;
        &.is-active {
          background-color: $color-primary;
          color: white;
          position: relative;
          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $color-primary;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 5px);
          }
        }
      }
    }
    .content {
      .about-desc {
        position: relative;
        img {
          width: 590px;
          height: 384px;
        }
        .desc-content {
          width: 845px;
          height: 320px;
          background-color: rgba($color-primary, 0.8);
          position: absolute;
          top: 44px;
          left: 355px;
          padding: 30px 50px 16px;
          flex-direction: column;
          color: white;
          .desc {
            max-height: 200px;
            overflow-y: hidden;
            line-height: 26px;
            color: rgba(white, 0.8);
          }
          .more {
            height: 36px;
            color: rgba(white, 0.8);
            cursor: pointer;
            i {
              color: rgba(white, 0.8);
              margin-left: 9px;
            }
            &:hover {
              color: white;
              i {
                color: white
              }
            }
          }
        }
      }
      .about-item {
        padding: 30px;
        background-color: #F0F1F5;
        .more {
          margin-top: 20px;
          color: $color-primary;
          cursor: pointer;
          i {
            color: $color-primary;
            margin-left: 9px;
          }
          &:hover {
            color: #F99735;
            i {
              color: #F99735;
            }
          }
        }
      }
    }
  }
</style>