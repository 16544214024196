export const routeNames = {
  HOME: 'home',
  ABOUT: 'about',
  NEWS: 'news',
  NOTICE: 'notice',
  PROJECT: 'project',
  DONATE: 'donate',
  ARTICLE: 'article',
}

export const navOptions = [
  { label: '首页', name: routeNames.HOME },
  { label: '关于我们', name: routeNames.ABOUT },
  // { label: '行愿人计划', name: routeNames.ABOUT, id: '6' },
  { label: '行愿资讯', name: routeNames.NEWS },
  { label: '信息公示', name: routeNames.NOTICE },
  { label: '行愿项目', name: routeNames.PROJECT },
]
