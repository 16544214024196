import Vue from 'vue'

const globalMixins = {
  methods: {
    jumpTo(name, query) {
      this.$router.push({ name, query }).catch(err => console.log(''))
    },
    openTo(name, query) {
      let newPage = this.$router.resolve({ name, query })
      window.open(newPage.href, '_blank')
    }
  }
}
Vue.mixin(globalMixins)
