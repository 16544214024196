<template>
  <div class="project-item" :style="{background}">
    <div class="mask transition-300">
      <div class="title fs22 transition-600">{{ info.title }}</div>
      <div class="sub-title fs14 transition-600">{{ info.subtitle }}</div>
      <div class="desc fs12 transition-600" v-html="info.desc"></div>
      <div class="btn flex-center transition-600" @click="jumpTo(routeNames.PROJECT, { id: info.id })">
        查看详情
      </div>
    </div>
  </div>
</template>

<script>
  import { routeNames } from "@/constants/nav";

  export default {
    name: "ProjectItem",
    props: {
      info: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        routeNames
      }
    },
    computed: {
      background() {
        return `url(${ this.info.img })`
      }
    }
  }
</script>

<style scoped lang="scss">
  .project-item {
    width: 386px;
    height: 462px;
    text-align: center;
    background-size: cover !important;
    color: white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .mask {
      width: 100%;
      height: 100%;
      padding: 0 30px;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(#261503, 0.5);
    }
    .title {
      margin-top: 205px;
    }
    .sub-title {
      margin-top: 16px;
      position: relative;
      padding: 0 8px;
      color: rgba(white, 0.8);
      &::before {
        content: '';
        width: 20px;
        height: 1px;
        background-color: #D0D0D0;
        position: absolute;
        top: 8px;
        left: calc(50% - 90px);
      }
      &::after {
        content: '';
        width: 20px;
        height: 1px;
        background-color: #D0D0D0;
        position: absolute;
        top: 8px;
        left: calc(100% - 95px);
      }
    }
    .desc {
      max-height: 100px;
      overflow-y: hidden;
      line-height: 22px;
      margin-top: 55px;
      color: rgba(white, 0.8);
      opacity: 0;
    }
    .btn {
      width: 140px;
      height: 40px;
      border-radius: 20px;
      background-color: rgba(white, 0.3);
      margin: 103px auto 0;
      opacity: 0;
      &:hover {
        background-color: white;
        color: $color-primary;
      }
    }
  }

  .project-item:hover {
    .mask {
      background-color: rgba(#A65300, 0.8);
    }
    .title {
      margin-top: 90px;
    }
    .sub-title {
      margin-top: 20px;
    }
    .desc {
      margin-top: 25px;
      opacity: 1;
    }
    .btn {
      margin-top: 63px;
      opacity: 1;
    }
  }
</style>
