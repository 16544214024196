<template>
  <vue-seamless :data="projectOptions" :class-option="{direction: 2}" class="project-panel">
    <div class="space-between">
      <project-item v-for="item in projectOptions" :key="item.id" :info="item"/>
    </div>
  </vue-seamless>
</template>

<script>
  import vueSeamless from 'vue-seamless-scroll';
  import ProjectItem from "@/components/Home/ProjectItem";

  export default {
    name: "ProjectPanel",
    components: { ProjectItem, vueSeamless },
    props: {
      projectOptions: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .project-panel {
    overflow: hidden;
  }
</style>