<template>
  <el-carousel
    ref="dotCarousel"
    class="dot-carousel"
    :height="height"
    indicator-position="none"
    @change="handlePageChange"
  >
    <slot/>
    <div v-if="number > 1" class="indicator-wrapper flex-center">
      <div
        v-for="index in number"
        :key="index"
        :class="['indicator-item', {'is-active': index === currentBanner + 1}]"
        @click="handleManuallyChange(index)"
      ></div>
    </div>
  </el-carousel>
</template>

<script>
  export default {
    name: "DotCarousel",
    props: {
      height: {
        type: String,
        required: true
      },
      number: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        currentBanner: 0,
      }
    },
    methods: {
      handlePageChange(currentIndex) {
        this.currentBanner = currentIndex
      },
      handleManuallyChange(index) {
        this.$refs.dotCarousel.setActiveItem(index - 1)
      }
    }
  }
</script>

<style scoped lang="scss">
  .dot-carousel {
    position: relative;
    overflow: hidden;
    .indicator-wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      .indicator-item {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 20px;
        background-color: #d8d8d8;
        cursor: pointer;
        z-index: 2;
        &:last-of-type {
          margin-right: 0;
        }
        &.is-active {
          background-color: $color-primary;
        }
      }
    }
  }
</style>

<style lang="scss">
  .dot-carousel {
    .el-carousel__arrow {
      display: none;
    }
  }
</style>
