<template>
  <div class="home-title flex-center fs32">
    {{ title }}
  </div>
</template>

<script>
  export default {
    name: "HomeTitle",
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .home-title {
    width: 128px;
    height: 62px;
    margin: 70px auto 50px;
    background: url("~@/assets/svgs/home_title.svg") no-repeat;
    background-size: cover;
    padding-top: 10px;
  }
</style>
