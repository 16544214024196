import axios from 'axios'
import { Message } from 'element-ui'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// Add a request interceptor
http.interceptors.request.use((config) => {
  return config
}, error => Promise.reject(error))

// Add a response interceptor
http.interceptors.response.use((response) => {
  if (response.status !== 200) return Message.error('接口请求失败')

  // 全局拦截器
  const res = response.data
  if (res.code === 200 || res.code === 0) {
    return res
  }

  return Promise.reject(response)
}, (error) => {
  /**
   * see details from https://github.com/axios/axios#interceptors
   * and https://github.com/axios/axios#handling-errors
   */
  return Promise.reject(error)
})

export default http
