<template>
  <div class="home-swiper" @mouseenter="hoverOnBanner = true" @mouseleave="hoverOnBanner = false">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in bannerList" :key="item.id" class="swiper-item">
        <div class="item-content" :class="{'is-active': index === currentBanner}">
          <img :src="item.img" alt="">
          <div class="banner-info fs22 space-between">
            <div class="text-ellipsis">{{ bannerList[index].title }}</div>
            <div>
              <span>{{ index + 1 }}</span>
              <span class="fs16">/{{ bannerList.length }}</span>
            </div>
          </div>

          <div v-if="index !== currentBanner" class="mask"></div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="banner-hot-zone" @click="handleBannerJump">
      <div
        v-show="hoverOnBanner"
        class="switch-btn flex-center pre-btn"
        @click.stop="handleSwitchBanner(false)"
      >
        <i class="iconfont iconicon_home_banner_left"/>
      </div>
      <div
        v-show="hoverOnBanner"
        class="switch-btn flex-center next-btn"
        @click.stop="handleSwitchBanner(true)"
      >
        <i class="iconfont iconicon_home_banner_right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { routeNames } from "@/constants/nav";

  export default {
    name: "HomeSwiper",
    props: {
      offsetWidth: {
        type: Number,
        required: true
      },
      bannerList: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        routeNames,
        hoverOnBanner: false,
        currentBanner: 0,
        swiperOptions: {
          height: 400,
          autoplay: true,
          loop: true,
          slidesPerView: 3,
          spaceBetween: 20,
          slidesOffsetBefore: (this.offsetWidth - 1200) / 2,
          slidesOffsetAfter: 3640 - this.offsetWidth / 2,
          on: {
            slideChange: () => this.currentBanner = this.swiper.realIndex
          }
        }
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    watch: {
      hoverOnBanner(newVal) {
        if (newVal) this.swiper.autoplay.stop()
        else this.swiper.autoplay.start()
      }
    },
    methods: {
      handleSwitchBanner(next) {
        if (next) this.swiper.slideNext()
        else this.swiper.slidePrev()
      },
      handleBannerJump() {
        const banner = this.bannerList[this.currentBanner]
        if (banner.url) window.location.href = banner.url
      }
    }
  }
</script>

<style scoped lang="scss">
  .home-swiper {
    width: 3640px;
    margin-left: calc(604px - 50vw);
    position: relative;
    .swiper-item {
      cursor: pointer;
      height: 400px;
      .item-content {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .banner-info {
        width: 100%;
        height: 80px;
        line-height: 80px;
        padding: 0 30px;
        background: linear-gradient(180deg, rgba(black, 0), rgba(black, 0.4));
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: none;
      }
    }
    .banner-hot-zone {
      width: 1200px;
      height: 400px;
      position: absolute;
      top: 0;
      left: calc(50vw - 4px - 600px);
      z-index: 2;
      cursor: pointer;
      .switch-btn {
        width: 60px;
        height: 60px;
        position: absolute;
        top: calc(50% - 30px);
        background-color: rgba(255, 255, 255, 0.2);;
        z-index: 9999;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        i {
          font-size: 40px;
          opacity: 0.8;
        }
      }
      .pre-btn {
        left: 40px;
      }
      .next-btn {
        right: 40px;
      }
    }
  }
</style>
